/* App.css */
.pop-bg-gray-200 {
    background-color: #edf2f7;
}

.pop-fixed {
    position: fixed;
}

.pop-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.pop-flex {
    display: flex;
}

.pop-items-center {
    align-items: center;
}

.pop-justify-center {
    justify-content: center;
}

.pop-z-50 {
    z-index: 50;
}

.pop-bg-black {
    background-color: #a9a9a9;
}

.pop-bg-opacity-70 {
    background-color: rgba(0, 0, 0, 0.7);
}

.pop-transition-opacity {
    transition: opacity 0.3s;
}

.pop-bg-cover {
    background-size: cover;
}

.pop-bg-center {
    background-position: center;
}

.pop-p-5 {
    padding: 1.25rem;
}

.pop-pt-0 {
    padding-top: 0;
}

.pop-rounded-lg {
    border-radius: 0.5rem;
}

.pop-transform {
    transform: scale(1);
}

.pop-transition-transform {
    transition: transform 0.3s;
    padding: 0rem 5rem 1rem 5rem;
    border-radius: 20px !important;
}

.pop-absolute {
    position: absolute;
}

.pop-top-4 {
    top: 1rem;
}

.pop-right-4 {
    right: 1rem;
}

.pop-text-white {
    color: #fff;
}

.pop-w-6 {
    width: 1.5rem;
}

.pop-h-6 {
    height: 1.5rem;
}

.pop-flex-col {
    flex-direction: column;
}

.pop-p-4 {
    padding: 1rem;
}

.pop-space-y-5>*+* {
    margin-top: 1.25rem;
}

.pop-bg-pink-500 {
    background-color: #E23B59;
}

.pop-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.pop-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.pop-mb-5 {
    margin-bottom: 1.25rem;
}

.pop-text-xl {
    font-size: 1.25rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.pop-text-2xl {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.pop-font-bold {
    font-weight: bold;
}

.pop-text-black {
    color: #000;
}

.pop-text-lg {
    font-size: 2.5vw !important;
}

.pop-text-p {
    font-size: 1.2vw !important;
}

.pop-mb-3 {
    margin-bottom: 0.75rem;
}

.pop-bg-white {
    background-color: #fff;
}

.pop-bg-white .pop-text-xl {
    margin-right: 2.5rem;
    color: #6E5AE2;
}

.pop-p-2 {
    padding: 0.5rem;
}

.pop-rounded-xl {
    border-radius: 0.75rem;
}

.pop-shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.pop-space-x-4>*+* {
    margin-left: 1rem;
}

.pop-w-16 {
    width: 4rem;
}

.pop-h-16 {
    height: 4rem;
}

.pop-rounded-full {
    border-radius: 50%;
}

.pop-border-2 {
    border-width: 2px;
}

.pop-border-gray-200 {
    border-color: #edf2f7;
}

.pop-text-gray-700 {
    color: #CA4E8D;
    font-weight: 500 !important;
    font-size: 13px;
    line-height: 2.2;
}

.pop_btn_dtm {
    background-color: #E23B59;
    border-radius: 50px;
    color: white !important;
    padding: 1rem 3rem !important;
    font-size: 24px;
}

.pop-in {
    animation: popIn 0.5s forwards;
}

@keyframes popIn {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@media screen and (min-width: 280px) and (max-width: 768px) {
    .pop-text-xl {
        font-size: 1rem;
    }

    .pop-text-2xl {
        font-size: 1rem;
        /* margin-bottom: 1rem; */
    }

    .pop-transition-transform {
        width: 90vw;
        height: 80vh;
        padding: 0rem 0rem 1rem 0rem;
        border-radius: 20px !important;
    }

    .pop_btn_dtm {
        font-size: 1.1rem; 
        padding: 0.8rem 3rem !important;
        margin-top: -1.250rem !important;
    }

    .pop-text-lg {
        font-size: 7vw !important;
    }

    .pop-text-p {
        font-size: 4vw !important;
    }

    .pop-p-2 {
        padding: 0.5rem 2rem 0.5rem 0.2rem;
    }


    .pop-bg-white .pop-text-xl {
        margin-right: 0rem;
        font-size: 1.3rem;
    }
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .pop-text-xl {
        font-size: 1.5rem;
    }

    .pop-text-2xl {
        font-size: 1.75rem;
    }

    .pop-transition-transform {
        width: 75%;
        height: 75%;
        padding: 0rem 1rem 1rem 1rem;
        border-radius: 20px !important;
    }

    .pop-text-lg {
        font-size: 4vw !important;
    }

    .pop-text-p {
        font-size: 2vw !important;
    }
}

/* Small Laptop */
@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .pop-text-xl {
        font-size: 1.75rem;
    }

    .pop-text-2xl {
        font-size: 2rem;
    }

    .pop-transition-transform {
        width: 85%;
        height: 75%;
        padding: 0rem 1rem 1rem 1rem;
        border-radius: 20px !important;
    }

    .pop-text-lg {
        font-size: 4vw;
    }

    .pop-text-p {
        font-size: 2vw !important;
    }
}