.footer {
    align-items: center;
    font-size: 16px;
    margin-bottom: 3%;
    margin-top: 5%;
    display: flex;
    justify-content: center;
}

@media (max-width: 767px) {
    .footer {
        align-items: center;
        font-size: 8px;
        margin-bottom: 9%;
        display: flex;
        justify-content: center;
    }
}

/* Detail */
.user-footer-bg{
    padding: 3%;
    border-radius: 10px;
}

.user-image-text {
    background: linear-gradient(90deg, rgb(218, 230, 235) 30%, rgb(218, 226, 230) 80%);
    background-size: cover;
    color: transparent;
    padding: 4%;
    text-align: justify;
    border-radius: 12px;
}

.user-footer-bg h1{
    color: #2577e0;
    padding-left: 2%;
    font-size: 1.5vw;
    margin-bottom: 1%;
    text-align: left !important;
}

@media (max-width: 767px) {
    .user-footer-bg h1 {
        margin-top: 10%;
        font-size: 5vw;
    }
}

@media (min-width: 768px) and (max-width: 1335px) {
    .user-footer-bg h1 {
        font-size: 5vw;
    }
}

.user-footer-bg p{
    color: rgb(32 28 28);
    padding-right: 2%;
    padding-left: 2%;
    font-size: 0.8vw;
    margin-top: 10px;
    margin-bottom: 20px;
    line-height: 1.5;
}

@media (max-width: 900px) {
    .user-footer-bg p{
    font-size: 12px;
    font-weight: 300;
    }
}

.user-footer-bg p a{
    color: rgb(32 28 28);
    text-decoration: underline;
    font-size: 14px;
    font-weight: 300;
}

@media (max-width: 900px){
    .user-footer-bg p a{
        font-size: 12px;
        font-weight: 300;
    }
}

@media (max-width: 900px){
h3 h7{
    display: block;
    } 
}


/* link */

.footer-link{
    border-bottom: 1px solid #2577e0;
    margin-bottom: 1.5%;
}

.footer-link-container{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    margin-bottom: 30px;
    padding-right: 2%;
    padding-left: 2%;
}

@media (max-width: 900px) {
    .footer-link-container{
        grid-template-columns: repeat(2,1fr);
        justify-items: start;
        padding-right: 2%;
        padding-left: 2%;
        gap: 25px;
    }
}

@media (min-width: 768px) and (max-width: 1335px) {
    .footer-link-container{
        grid-template-columns: repeat(2,1fr);
    }
}

@media (max-width: 900px) {
    .footer-link-main{
        margin-left: 0;
    }
}

.footer-link h3{
    color: black;
    font-size: 1vw;
    margin-top: 12px;
    margin-bottom: 10px;
}

@media (max-width: 800px) {
    .footer-link h3{
        font-size: 3vw;
    }
}

@media (min-width: 768px) and (max-width: 1335px) {
    .footer-link h3{
        font-size: 3vw;
    }
}

.footer-link h4{
    font-size: 0.8vw;
    font-weight: 200;
    color: rgb(32 28 28);
    line-height: 1.8;
}

@media (max-width: 800px) {
    .footer-link h4{
        font-size: 2.8vw;
        font-weight: 300;
    }
}

@media (min-width: 768px) and (max-width: 1335px) {
    .footer-link h4{
        font-size: 2.8vw;
    }
}

.footer-link h4:hover{
    text-decoration: underline;
}

/* copy right */

.user-footer-copy{
    color: black;
    border-top: 1px solid #2577e0;
}

.user-footer-iconwith-copy{
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    justify-content: center;
}

@media (max-width: 900px) {
    .user-footer-iconwith-copy{
        font-size: 10px;
        grid-template-columns: repeat(3,1fr);
    }
}

.user-footer-iconwith-copy a img{
    width: 40px;
    height: auto;
    margin-bottom: 10px;
}

.user-footer-iconwith-copy a{
    color: black;
    text-align: center;
}

.user-footer-iconwith-copy a:hover{
    text-decoration: underline;
}

.user-footer-icon{
    display: grid;
    align-items: center;
    padding: 20px;
}

.user-footer-bg h5{
    color: black;
    font-size: 15px;
    font-weight: 400;
}

.user-footer-copyright{
    color: black;
    padding-right: 2%;
    padding-left: 2%;
    text-align: center;
    margin-top: 30px;
}

.user-footer-copyright h6{
    font-size: 20px;
    font-weight: 400;
}

@media (max-width: 767px) {
    .user-footer-copyright h6{
        font-size: 10px;
    }
}