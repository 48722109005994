@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Gujarati:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Noto+Sans+Gujarati&family=Poppins:wght@400&display=swap');
@font-face {
  font-family: Anek Gujarati;
  src: url(../AnekGujarati-Regular.ttf);
}

* {
  outline: none;
  border: none;
  box-sizing: border-box;
  transition: 0.5s;
  -webkit-tap-highlight-color: transparent !important;
  letter-spacing: 0.5px;
  /* font-family: "Noto Sans Gujarati" !important; */
  font-family: 'Poppins', sans-serif !important;

}

body {
  background-color: var(--background) !important;
  margin: 0 !important;
  padding: 0 !important;
}

img {
  user-select: none;
}

a {
  text-decoration: none;
}

#content1::before {
  content: "\a";
  white-space: pre;
}

.row {
  --bs-gutter-x: 0rem !important;
}

.btn {
  border: none !important;
}

@media (max-width: 767px) {
  #content1::before {
    content: "\a";
    white-space: inherit;
  }

  #content2::before {
    content: "\a";
    white-space: inherit;
  }

  .row {
    --bs-gutter-x: 0rem !important;
  }
}

:root {
  --main: #2577e0;
  --mainbackground: #eef0f2;
  --white: #fff;
  --black: #000;
}

a {
  text-decoration: none !important;
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.usercontainer {
  max-width: 1400px !important;
}

/* reveal animation */

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-bottom {
  animation: fade-bottom 0.4s ease-in;
}

.active.fade-left {
  animation: fade-left 0.5s ease-in;
}

.active.fade-right {
  animation: fade-right 0.5s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* reveal animation end */

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .sub_container_teacher {
    max-width: 850px !important;
  }
}

/* Heading css */

.hero_img img {
  width: 30vw;
  margin-left: 5vw;
}

.user_sub_hero {
  text-align: justify;
}

.user_hero h1 {
  font-size: 2.8rem;
}

.All_paragraph {
  font-size: 1.2rem;
}

.user_btn_clr {
  background-color: var(--main) !important;
  color: var(--white) !important;
  border-radius: 50px !important;
  font-size: 1.2rem !important;
  padding: 0.5rem 1.5rem 0.25rem 1.5rem !important;
}

.static_detail {
  margin-left: -13rem;
}

/* Heading css end*/

/* Achive css */

.All_achive {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.All_sub_achive {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  justify-items: center !important;
}

.achive img {
  width: 75px;
}

/* Achive css end */

/* Teacher profile css */

.main_title h1 {
  color: var(--main);
  font-size: 2.5rem;
}

.teacher_profile {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-sizing: content-box !important;
  padding: 1.5rem 0rem;
  margin: 3rem 0rem !important;
}

.Quiz_profile {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-sizing: content-box !important;
  padding: 1.5rem 0rem;
  margin: 3rem 0rem !important;
}

.static_profile {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-sizing: content-box !important;
  padding: 1.5rem 0rem;
  margin: 3rem 0rem !important;
}

.teacher_img img {
  width: 10vw !important;
  mix-blend-mode: multiply;
}

.teacher_detail {
  margin-left: -3rem;
  margin-right: -10rem;
  margin-top: 0.5rem !important;
}

.arrow_img {
  margin-left: 10rem;
}

.static_img {
  margin-left: 2rem !important;
}

.teacher_detail h3 {
  color: var(--black);
  font-size: 1.75rem;
}

.teacher_detail span {
  top: -0.45rem;
  position: relative;
}

.teacher_detail em {
  color: var(--main);
  font-style: normal;
}

.teacher_detail p {
  color: var(--black);
  font-size: 1.25rem;
}

.arrow_img img {
  width: 4vw !important;
}

.user_btn_clr img {
  width: 1vw;
}

#nmms_container_1 {
  text-align: center;
}

#nmms_container_1 img {
  width: 10rem;
  mix-blend-mode: multiply;
}

#nmms_quiz_year {
  display: flex;
  position: relative;
  justify-content: center;
}

.underline {
  position: absolute;
  bottom: 40px;
  left: 0rem;
  width: 0;
  height: 2px;
  background-color: var(--main);
  transition: width 0.3s ease, left 0.3s ease;
}

#btn2023.active~#underline {
  width: 235px;
  left: 44.7rem;
}

#btn2024.active~#underline {
  width: 235px;
  left: 23.2rem;
}


.Quiz_detail {
  margin-left: 5rem;
  margin-right: -10rem;
  margin-top: 0.5rem !important;
}

.Quiz_detail h3 {
  color: var(--black);
  font-size: 2.5rem;
}

.Quiz_detail p {
  color: var(--black);
  font-size: 1.5rem;
}

.Quiz_img img {
  width: 10vw !important;
  mix-blend-mode: multiply;
  margin-left: 12rem;
}

/* Teacher profile css end */

/* ------------------------------------ */

/* Responsive */

@media (max-width: 767px) {
  .hero_img {
    text-align: center;
  }

  .hero_img img {
    width: 75vw;
    margin-left: 0vw;
  }

  .user_hero h1 {
    font-size: 8vw;
  }

  .user_hero p {
    font-size: 4vw;
  }

  .user_sub_hero {
    text-align: center;
  }

  .user_btn_clr {
    font-size: 3.5vw !important;
    padding: 0.3rem 1.2rem 0.25rem 1.2rem !important;
  }

  .All_achive {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .All_heading h1 {
    font-size: 6vw !important;
  }

  .All_achive_767 {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    margin-left: -2rem !important;
    row-gap: 20px;
  }

  .All_achive_767 img {
    width: 15vw;
  }

  .All_achive_767 h3 {
    font-size: 5vw;
  }

  .All_achive_767 p {
    font-size: 4vw;
    margin-right: -1rem;
  }

  .All_sub_achive {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .All_sub_achive span {
    font-size: 1rem !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .sub_container_teacher {
    max-width: 320px !important;
  }

  .teacher_profile {
    padding: 0.8rem 0rem !important;
    border-radius: 10px;
    margin: 1.5rem 0rem !important;
  }

  .Quiz_profile {
    padding: 0.8rem 0rem !important;
    border-radius: 10px;
    margin: 1.5rem 0rem !important;
  }

  .static_profile {
    padding: 0.8rem 0rem !important;
    border-radius: 10px;
    margin: 1.5rem 0rem !important;
  }

  .main_title h1 {
    font-size: 6vw;
  }

  .teacher_img img {
    width: 20vw !important;
  }

  .teacher_detail {
    margin-top: 0rem !important;
    margin-left: 0rem;
    margin-right: -5rem;
  }

  .arrow_img {
    margin-left: 4.2rem;
  }

  .static_img {
    margin-left: 1rem !important;
  }

  .teacher_detail h3 {
    color: var(--black);
    font-size: 4vw;
    margin-bottom: 0.2rem !important;
  }

  .teacher_detail span {
    top: -0.2rem !important;
    position: relative !important;
  }

  .teacher_detail em {
    color: var(--main);
    font-style: normal;
  }

  .teacher_detail p {
    color: var(--black);
    font-size: 3.5vw;
    margin-bottom: 0.2rem !important;
  }

  .arrow_img img {
    width: 5vw !important;
  }

  .user_btn_clr img {
    width: 3.5vw;
  }

  .static_detail {
    margin-left: -3.7rem;
  }

  .static_detail h3 {
    font-size: 4vw;
    margin-bottom: 0.2rem !important;
  }

  /* year button */

  #nmms_quiz_year {
    justify-content: start;
  }

  #nmms_quiz_year button {
    font-size: 0.9rem !important;
    margin: 3rem 1rem 1rem 2rem !important;
    padding: 0 !important;
  }

  .underline {
    position: absolute;
    bottom: 10px;
    height: 1.2px;
  }

  #btn2023.active~#underline {
    width: 122px;
    left: 12.9rem;
  }

  #btn2024.active~#underline {
    width: 122px;
    left: 2rem;
  }

  /* year button end */

  .Quiz_detail {
    margin-top: 0.5rem !important;
    margin-left: 2rem;
    margin-right: -5rem;
  }

  .Quiz_detail h3 {
    color: var(--black);
    font-size: 1.6rem;
    margin-bottom: 0.2rem !important;
  }

  .Quiz_detail p {
    color: var(--black);
    font-size: 1.2rem;
    margin-bottom: 0.2rem !important;
  }

  .Quiz_img img {
    width: 20vw !important;
    margin-left: 4rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  /* body{
    margin-left: 40px !important;
    margin-right: 40px !important;
  } */
}

@media (min-width: 1024px) and (max-width: 1279px) {
  /* body{
    margin-left: 50px !important;
    margin-right: 50px !important;
  } */
}

/* //////////////////////////////////////// */

/* Dtm Home */

.form_select {
  width: 35% !important;
  margin-left: 21rem;
  cursor: pointer;
}

.sub_select {
  width: 35% !important;
  cursor: pointer;
}

.dtm {
  margin-left: 8.5rem !important;
}

.dtm_hero_img img {
  width: 45vw;
  margin-left: -6rem;
}

.dtm_hero h1 {
  font-size: 4.5rem;
  line-height: 6.5rem;
}

.dtm_hero p {
  font-size: 2rem;
  margin-bottom: 2.5rem;
}

.dtm_btn_clr {
  border: 2px solid var(--main) !important;
  background-color: inherit !important;
  color: var(--main) !important;
  border-radius: 50px !important;
  font-size: 1.5rem !important;
  padding: 0.8rem 3rem 0.8rem 3rem !important;
}

.dtm_btn_arrow_1 {
  background-color: var(--main) !important;
  color: var(--white) !important;
  border-radius: 50px !important;
  font-size: 1.5rem !important;
  padding: 0.2rem 3rem 0.2rem 3rem !important;
  position: absolute;
}

.dtm_btn_arrow_2 {
  background-color: var(--main) !important;
  color: var(--white) !important;
  border-radius: 50px !important;
  font-size: 1.5rem !important;
  padding: 0.2rem 3rem 0.1rem 3rem !important;
}

.dtm_btn_arrow_3 {
  background-color: var(--main) !important;
  color: var(--white) !important;
  border-radius: 50px !important;
  font-size: 1.5rem !important;
  padding: 0.2rem 3rem 0rem 3rem !important;
  position: absolute;
}

.dtm_btn_arrow_4 {
  background-color: var(--main) !important;
  color: var(--white) !important;
  border-radius: 15px !important;
  font-size: 1.7rem !important;
  padding: 0.2rem 4rem 0.1rem 4rem !important;
  margin: 0rem 2rem;
}

.dtm_btn_arrow_1 img {
  margin-left: 1rem !important;
  width: 3vw !important;
  height: 4vw !important;
}

.dtm_btn_arrow_2 img {
  margin-top: -0.1rem;
  margin-left: 1rem !important;
  width: 3vw !important;
  height: 4vw !important;
}

.dtm_btn_arrow_3 img {
  margin-left: 1rem !important;
  width: 3vw !important;
  height: 4vw !important;
}

.dtm_btn_arrow_4 img {
  margin-top: -0.1rem;
  margin-left: 1rem !important;
  width: 3vw !important;
  height: 4vw !important;
}

.dtm_team {
  margin-left: -5rem !important;
  gap: 5rem;
}

.dtm_team span {
  vertical-align: super !important;
}

.dtm_teamimg {
  width: 22.5rem;
  border-radius: 20px;
  border: 6px solid var(--white);
}

/* ------------------------------------ */

/* Responsive */

@media (max-width: 767px) {
  .form_count {
    text-align: left;
    margin-left: 2rem;
  }

  .form_select {
    display: grid !important;
    margin-top: -2em;
    width: 42% !important;
    margin-left: 11rem;
  }

  .sub_select {
    width: 55% !important;
    margin-left: 2rem;
  }

  #dtm {
    margin-top: 5em !important;
  }

  #dtm  .form_count, .form_select {
    margin-bottom: -1em !important;
  }

  .dtm {
    margin-left: 0rem !important;
    text-align: center;
  }

  .dtm_hero_img img {
    width: 90vw;
    margin-top: -1em;
    margin-left: 0rem;
  }

  .dtm_hero h1 {
    font-size: 2rem;
    text-align: center;
    line-height: 0rem;
  }

  .dtm_hero p {
    font-size: 1.1rem;
    text-align: center;
    margin: 2rem 0rem 1rem 0rem;
  }

  .dtm_btn_clr {
    font-size: 6vw !important;
    padding: 0.8rem 3rem 0.45rem 3rem !important;
  }

  .dtmbtn{
    justify-items: center;
    margin-bottom: 1.5em;
  }

  .dtm_btn_arrow_1 {
    font-size: 1.2em !important;
    padding: 0.3rem 1.5rem 0.1rem 1.5rem !important;
  }

  .dtm_btn_arrow_2 {
    padding: 0.3rem 1.5rem 0.1rem 1.5rem !important;
    font-size: 1.2em !important;
    margin: 0em -2em;
  }

  .dtm_btn_arrow_3 {
    font-size: 1.025rem !important;
    padding: 0.1rem 1.5rem 0rem 1.5rem !important;
    position: relative;
  }

  .dtm_btn_arrow_4 {
    padding: 0.8rem 1.3rem 0.4rem 1.3rem !important;
    border-radius: 10px !important;
    font-size: 1.2rem !important;
    margin: 0rem 0.2rem !important;
  }

  .dtm_btn_arrow_1 img {
    margin-top: -0.2rem !important;
    margin-left: 0.6rem !important;
    width: 2em !important;
    height: 2.5em !important;
  }

  .dtm_btn_arrow_2 img {
    margin-top: -0.2rem !important;
    margin-left: 0.6rem !important;
    width: 2em !important;
    height: 2.5em !important;
  }

  .dtm_btn_arrow_3 img {
    margin-top: -0.1rem !important;
    margin-left: 0.6rem !important;
    width: 2rem !important;
    height: 3rem !important;
  }

  .dtm_btn_arrow_4 img {
    margin-top: -0.2rem !important;
    margin-left: 0.6rem !important;
    width: 9vw !important;
    height: 9vw !important;
  }

  .dtm_team {
    margin-left: 0rem !important;
    gap: 2rem;
    text-align: left;
  }

  .dtm_teamimg {
    width: 19em;
    border-radius: 20px;
    border: 6px solid var(--white);
  }
}

/* Dtm Home end */

/* //////////////////////////////////// */

/* statics */

.dtm_statics p {
  line-height: 1.8;
  font-size: 1.5rem;
}

.dtm_statics img {
  width: 37rem;
}

.dtm_statics_img_1 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.statics_paragraph {
  position: relative;
  margin-bottom: 20px;
}

.top {
  top: 9.5vw !important;
}

.statics_paragraph p {
  position: absolute;
  text-align: justify;
  top: 8.5vw;
  left: 3.9rem;
  margin: -5.5rem;
  padding: 3rem;
  font-size: 1.3vw;
  font-weight: 500;
  width: 100%;
  line-height: 1.6 !important;
}

.dtm_statics_img_1 img {
  width: 25vw;
  height: auto;
}

.dtm_statics_img_2 img {
  width: 15rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

#howtoworkdtm{
  grid-template-columns: repeat(2,1fr);
  justify-items: center;
}

#howtoworkdtm img{
  width: 80%;
  margin-left: 3em;
  margin-bottom: 2em;
  height: auto;
}

#content3::before {
  content: "\a";
  white-space: pre;
}

/* //////////////////////////////// */

/* responsive */

@media (max-width: 767px) {
  #content3::before {
    content: "\a";
    white-space: pre;
  }

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .dtm_container {
    max-width: 320px !important;
  }

  .dtm_statics h1 {
    margin-top: 0rem !important;
    font-size: calc(1.375rem + 1.3vw);
  }

  .dtm_statics h2 {
    margin-top: 0.5rem !important;
    font-size: 1.2rem;
  }

  .dtm_statics h3 {
    margin-top: -1rem !important;
    font-size: 1.2rem;
    line-height: 1.5;
  }

  .dtm_statics p {
    margin-top: -0.2rem !important;
    line-height: 1.5;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem !important;
    text-align: justify !important;
  }

  .dtm_statics img {
    width: 20rem;
  }

  .dtm_statics_img_1 {
    margin: 2rem !important;
  }

  .statics_paragraph {
    position: relative;
    margin-bottom: 20px;
  }

  .top {
    top: 21vw !important;
  }

  .statics_paragraph p {
    position: absolute;
    text-align: justify;
    top: 19.5vw;
    left: 5.2rem;
    padding: 0rem;
    font-size: 1rem !important;
  }

  .dtm_statics_img_1 img {
    margin-left: -1.5rem !important;
    margin: 0rem;
    width: 19rem;
    height: auto;
  }

  .dtm_statics_img_2 img {
    width: 9.5rem;
    margin-bottom: 1rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }

  #howtoworkdtm{
    grid-template-columns: repeat(1,1fr);
    justify-items: center;
  }
  
  #howtoworkdtm img{
    width: 100%;
    margin-left: 0em;
    margin-bottom: 1em;
    height: auto;
  }
}

/* statics end */

/* ///////////////////////////////// */

/* dtm team */
.dtm_1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  margin-top: 3em;
  margin-bottom: 5rem;
}

#col_1 {
  margin-left: 5.5rem;
  margin-right: -4rem;
}

#col_1 pre {
  font-size: 1.2rem !important;
}

#col_1 span {
  font-weight: 600;
  top: -0.2rem;
  position: relative;
}

#col_1 h1 {
  font-size: 1.8rem;
}

#col_1 p {
  font-size: 1.2rem;
}

#col_1 img {
  top: -0.5rem;
  position: relative;
  width: 10rem;
  height: 8.5rem;
}

.dtm_teamimg_for_team {
  width: 34rem;
  border-radius: 20px;
  border: 6px solid var(--white);
}

#card {
  border-radius: 15px;
  align-items: flex-start;
}

#card-body {
  gap: 1rem;
}

#card-body em {
  font-style: normal;
  margin-left: 10px;
}

#card-detail {
  margin-left: 10px;
}

#card span {
  font-weight: 600;
  top: -0.2rem;
  position: relative;
}

/* responsive */

@media (max-width: 767px) {
  #container_1 {
    text-align: center;
  }

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  #container_1,
  #container_2 {
    max-width: 320px !important;
  }

  #container_2 .text-center{
    text-align: left !important;
    margin-top: 2.5em !important;
    margin-bottom: 1.5em !important;
  }

  #row {
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
  }

  #col {
    text-align: justify;
    margin-top: 1.5rem;
  }

  .dtm_teamimg_for_team {
    width: 20rem;
    border-radius: 20px;
    border: 6px solid var(--white);
  }

  #col h2 {
    font-size: 2rem;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  #col h3 {
    font-size: 1.2rem;
  }

  #col p {
    font-size: 1.3rem;
  }

  .dtm_1 {
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
    margin-bottom: 0em;
  }

  #col_1 {
    margin-left: 32px;
    margin-right: 0rem;
  }

  #col_1 pre {
    font-size: 1.1rem !important;
    margin-right: -4em;
  }

  #col_1 span {
    font-weight: 600;
    top: -0.25rem;
    position: relative;
  }

  #col_1 h1 {
    font-size: 1.4rem;
    margin-right: -1.5em;
  }

  #col_1 p {
    font-size: 1.1rem;
  }

  #col_1 img {
    top: -0.8rem !important;
    position: relative;
    width: 8rem;
    height: 8rem;
  }

  #col_md {
    margin-top: 0.2rem !important;
  }

  #card {
    border-radius: 10px;
  }

  #card-body {
    margin: 0rem 1rem;
    padding: 0.5rem 0.2rem !important;
    gap: 0.5rem;
  }

  #card h1 {
    font-size: 1.3rem;
    margin-right: -30px;
  }

  #card p {
    font-size: 0.9rem;
    line-height: 1.2;
    margin-right: -10px;
  }

  #card img {
    width: 5rem;
    height: 5rem;
    margin-left: -0.7em;
  }

  #card button{
    font-size: 0.7rem;
    padding-top: 0.5em !important;
  }
}

/* Pop up dtm */

.pop_btn {
  border-radius: 50px !important;
  padding: 1rem !important;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out !important;
  transform: translate(0, -250px) !important;
}

.modal-content {
  border: none !important;
  align-items: center !important;
  background-color: transparent !important;
  transform: translateY(100%);
}

.modal-content.show {
  transform: translateY(0%);
}

#img-fluid {
  position: relative;
  left: 0;
  width: 100% !important;
  height: 500px;
  object-fit: unset;
  opacity: 2;
}

.modal-body {
  position: absolute !important;
  height: calc(100vh - 300px);
}

#left_arrow {
  margin-left: 1.8rem;
  padding: 8px 10px 3px 10px;
  border-radius: 50%;
  font-size: 20px;
}

@media (max-width: 767px) {
  .modal-dialog {
    margin: 0 !important;
  }

  .modal-content {
    border: none !important;
    align-items: center !important;
    background-color: transparent !important;
    transform: translateY(100%);
  }

  .modal-content.show {
    transform: translateY(0%);
  }

  #img-fluid {
    position: relative;
    left: 0;
    height: 570px;
    border-top-left-radius: 50px !important;
    border-top-right-radius: 50px !important;
    object-fit: unset;
    opacity: 2;
  }

  .modal-body {
    height: calc(100vh - 500px);
  }

  #left_arrow {
    padding: 8px 8px 3px 8px;
    border-radius: 50%;
    margin-left: 2rem;
    font-size: 15px;
  }
}

/* Pop up dtm end */