:root {
    --main: #2577e0;
    --mainbackground: #eef0f2;
    --white: #fff;
    --black: #000;
    --pink: #EB5885;
}

#blog-container_1 img {
    margin-left: 5rem;
    width: 30rem;
    height: 30rem;
}

#blog-container_1 .user_hero {
    margin-left: 5rem;
    margin-right: 3rem;
}

#blog-container_1 p {
    text-align: justify;
    line-height: 1.8;
}

/* //////////////// */

/* Blog home */

#blog img {
    border-radius: 15px;
    width: 22rem;
    height: 14rem;
}

#blog span {
    top: -0.2rem;
    position: relative;
    margin: 0px 8px;
}

#blog i {
    margin-right: 5px;
}

#blog-container_2 .row {
    margin: 0rem 1.5rem;
}

#All_topic {
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    border-radius: 15px;
}

#All_topic #topic_1 {
    border-radius: 15px;
}

/* Blog home end */

/* Adjust header and topic styling on small screens */
@media (max-width: 768px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    #blog {
        max-width: 370px !important;
    }

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    #blog-container_1,
    #blog-container_2 {
        max-width: 330px !important;
    }

    #blog-container_1 {
        text-align: center;
    }

    #blog-container_1 img {
        margin-left: 0rem;
        width: 20rem;
        height: 20rem;
    }

    #blog-container_1 .user_hero {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    #blog-container_1 h1 {
        font-size: 2.3rem !important;
    }

    #blog-container_1 p {
        text-align: center;
        line-height: 1.8;
        font-size: 1rem !important;
    }

    #blog .row {
        gap: 0px !important;
    }

    #blog .col {
        flex: none;
    }

    #blog img {
        border-radius: 10px !important;
        width: 22rem;
        height: 12rem;
    }


    #blog h1 {
        font-size: 6vw;
    }

    #blog p {
        font-size: 3.5vw;
    }

    #blog span {
        top: -0.3rem;
        margin: 0px 8px;
    }

    #blog i {
        font-size: 13px !important;
    }

    #blog-container_2 .row {
        margin: 0rem;
        margin-top: 0.5rem !important;
    }

    #All_topic {
        text-align: center;
        grid-template-columns: repeat(2, 1fr);
    }

    #All_topic img {
        width: 6rem !important;
    }

    #All_topic h1 {
        font-size: 1.2rem;
        padding: 0% !important;
    }
}

/* Make blog cards stack vertically */
@media (max-width: 992px) {}

/* Adjust topic card sizes */
@media (max-width: 1200px) {}

@media (max-width: 768px) {}

/* ////////////////////////////// */

/* blog content */

#blog_content_img img {
    width: 100%;
    height: 650px;
}

#blog_content .col {
    grid-template-columns: repeat(1, 1fr);
    position: absolute;
    top: 27rem;
    left: 5rem;
}

#blog_content .col .d-flex {
    gap: 8rem;
}

#blog_content .col h1 {
    color: var(--white);
    font-size: 4rem;
    font-weight: bolder;
    margin-right: 20rem;
}

#blog_content .col h3 {

    color: var(--white);
}

#blog_content .col i {

    color: var(--white);
}

#blog_content .col p {
    font-size: 2rem;
    color: var(--white);
}

#blog_content span {
    position: relative;
    top: -6px;
    color: var(--white);
}

#blog_content_details {
    max-width: 1000px;
    margin: 2rem auto !important;
    padding: 0rem;
}

#blog_content_details pre {
    text-align: justify;
    font-size: 1.15rem;
    /* margin: 0rem -2rem; */
    line-height: 2;
}

@media (max-width: 768px) {
    #blog_content_img img {
        width: 100%;
        height: 250px;
    }

    #blog_content .col {
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        margin: 2rem 2rem !important;
        top: 0rem;
        left: 0rem;
    }

    #blog_content .col .d-flex {
        gap: 0rem;
    }

    #blog_content .col .d-flex .mx-5 {
        margin-right: 0rem !important;
        font-size: 14px;
    }

    #blog_content .col h1 {
        color: var(--black);
        font-size: 1.5rem;
        margin-right: -40px;
    }

    #blog_content .col h3 {
        color: var(--black);
        font-size: 1.3rem;
    }

    #blog_content .col i {
        color: var(--black);
    }


    #blog_content .col p {
        font-size: 1.3rem;
        color: var(--black);
    }

    #blog_content span {
        position: relative;
        top: -2px;
        color: var(--black);
    }

    #blog_content_details {
        margin: 2.5rem auto !important;
        padding: 0rem;
    }

    #blog_content_details .row {
        margin: 0rem 1.5rem !important;
    }

    #blog_content_details p {
        text-align: justify;
        font-size: 1.2rem;
        margin: 0rem 0.5rem;
        line-height: 2;
    }
}

/* blog content end */


/* blog category */

#blog_category_container {
    width: 100%;
    /* display: grid; */
}

#blog_category_container h1 {
    position: relative;
    background-color: var(--pink);
    padding: 1rem 5rem;
    text-align: center;
    padding-top: 1.5rem;
    margin-top: -2.8rem;
    /* margin: 0rem 30rem; */
    margin-left: 30rem;
    margin-right: 30rem;
    z-index: 1;
    border-radius: 15px;
    color: var(--white);
}

#blog_category_container .col img {
    position: relative;
    width: 100%;
    height: 700px;
    top: 15%;
    left: 0;
    bottom: 0;
    z-index: 0;
}

#blog_category_content {
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    margin-top: 8rem;
}

#blog_category_content img {
    width: 17rem;
    border-radius: 15px;
    border: 5px solid var(--white);
}

#blog_category_content h1 {
    margin-top: 0.6rem;
    font-size: 1.8rem;
    color: var(--black);
}

#blog_category_content h3 {
    font-size: 1.3rem;
    color: var(--black);
    margin: 1rem 0rem !important;
}

#blog_category_content p {
    font-size: 1.3rem;
    color: var(--black);
    margin-bottom: 0rem !important;
}

#blog_category_content span {
    color: var(--black);
    margin-bottom: 0rem !important;
}

#blog_category_content em {
    font-size: 1.3rem;
    font-style: normal;
    color: var(--pink);
}

@media (max-width: 768px) {
    #blog_category_container {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 5rem;
    }

    #blog_category_container .col {
        padding-top: 0%;
    }

    #blog_category_container h1 {
        position: relative;
        text-align: center;
        padding: 0.5rem 1rem;
        padding-top: 0.8rem;
        margin: 0 5rem;
        font-size: 1rem;
        border-radius: 8px;
        margin-top: -1.2rem;
    }

    #blog_category_container .col img {
        height: 250px;
    }

    #blog_category_content {
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        margin-top: 0rem;
        margin-bottom: 2rem;
        max-width: 350px;
        justify-content: center;
    }

    #blog_category_content img {
        width: 8rem;
        height: 90px;
        border-radius: 8px;
        border: 4px solid var(--white);
        margin-right: 0px;
    }

    #blog_category_detail {
        margin-left: -3rem;
    }

    #blog_category_content h1 {
        font-size: 1.3rem;
        margin-top: 0.4rem;
    }

    #blog_category_content h3 {
        font-size: 1rem;
        margin: 0.5rem 0rem !important;
    }

    #blog_category_content p {
        font-size: 1rem;
        margin-bottom: 0rem;
    }

    #blog_category_content em {
        font-size: 1rem;
    }

    #blog_category_content span {
        margin: 0rem 0.5rem !important;
        font-size: 14px !important;
        position: relative;
        top: 0px;
    }
}

/* blog category end */

/* image-shadow */

.image-shadow {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 38%;
    z-index: 0;
    background: linear-gradient(rgba(0.2, 0.2, 0.2, 0.4) 6%, rgba(2, 2, 2, 2) 150%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

@media (max-width: 768px) {
    .image-shadow {
        width: 0%;
    }
}

/* image-shadow end*/

.skeleton-wrapper {
    position: relative;
    width: 20rem;
    height: 14rem;
}

#skeleton-wrapper {
    margin-bottom: 10%;
}

.skeleton-border {
    border-radius: 10px !important;
}

.skeleton-image {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #e2e5e7;
    background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
}

.skeleton-text {
    background-color: #e2e5e7;
    background-image: linear-gradient(90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
    display: inline-block;
}

@keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}

@media (max-width: 768px) {
    #skeleton-image {
        height: 100% !important;
    }
}

pre{
    white-space: pre-line;
}