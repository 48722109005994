#user_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
}

#user_container img {
    width: 16vw;
    height: 16vw;
}

#user_container #user_details {
    margin-left: -9em;
}

#user_container p {
    font-size: 1.3em;
    text-align: left;
}

#user_container .btn-primary {
    background-color: #2577e0;
    font-size: 1.3em;
}

#contact_container {
    margin-top: 5rem;
    grid-template-columns: repeat(2,1fr);
}

#contact_container .d-flex {
    margin: 0rem 3rem !important;
}

#contact_container img{
    width: 8vw;
}

#contact_container h1{
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-size: 3rem;
    color: #2577e0 !important;
}

#contact_container h2{
    font-size: 1.8rem;
    margin-left: 6rem !important;
}

#contact_container a {
    background-color: rgb(235, 88, 133);
    font-size: 24px !important;
    padding-top: 0.8rem;
}

@media (max-width:767px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    #user_container {
      max-width: 320px !important;
    }

    #user_container {
        grid-template-columns: repeat(1, 1fr);
    }

    #user_container img {
        margin-left: -1em;
        width: 100%;
        height: 50vw;
    }

    #user_container #user_details {
        margin-left: -1em;
        margin-right: 1em;
    }

    #user_container h1 {
        font-size: 1.3em;
    }

    #user_container p {
        font-size: 0.9em;
    }
    
    #user_container .btn-primary {
        font-size: 0.9em;
    }

    #contact_container {
        margin-top: 5rem;
        grid-template-columns: repeat(1,1fr);
        gap: 50px;
    }

    #contact_container .d-flex {
        margin: 0rem 0rem !important;
    }
    
    #contact_container img{
        width: 20vw;
    }
    
    #contact_container h1{
        margin: 0rem 0.5rem !important;
        font-size: 1.5rem;
    }
    
    #contact_container h2{
        font-size: 1.5rem;
        margin-left: 3rem !important;
    }
    
    #contact_container a {
        font-size: 16px !important;
        padding: 0.8rem 2rem 0.5rem 2rem !important;
    }
}